import React, { useState, useEffect } from "react";
import styles from "./ImagesTimeSlider.module.css";


const ImagesTimeSlider = ({ dateIni, ticksNumber, intervalIni, intervalEnd, onSliderValueChange }) => {
    const [sliderValue, setSliderValue] = useState(0);
    const [tickDates, setTickDates] = useState([]);
    const [nowXPosition, setNowXPosition] = useState(0);
    const [intervalXPosition, setIntervalXPosition] = useState(0);

    useEffect(() => {
        if (dateIni instanceof Date) {
            generateTickDates();
            const currentUTC = Date.now() + new Date().getTimezoneOffset() * 60 * 1000;
            setNowXPosition((currentUTC - dateIni.getTime()) / (dateEnd.getTime() - dateIni.getTime()));
            setIntervalXPosition((intervalIni - dateIni.getTime()) / (dateEnd.getTime() - dateIni.getTime()));
            if (sliderValue === 0) {
                setSliderValue(Math.floor(Math.min(Math.max(nowXPosition || 0, 0), 1) * 24 * (ticksNumber - 1)));
            }
        }
        onSliderValueChange(sliderValue);
    }, [dateIni, ticksNumber]);

    const dateEnd = new Date(dateIni.getTime() + (24 * (ticksNumber - 1)) * 60 * 60 * 1000);

    const generateTickDates = () => {
        const tickValues = Array.from({ length: ticksNumber }, (_, index) => index);
        const firstTickDate = new Date(dateIni);
        const tickDates = tickValues.map((tick) => {
            const date = new Date(firstTickDate.getTime() + tick * 24 * 60 * 60 * 1000);
            const formattedDate = date.toLocaleString("es-ES", { day: "2-digit", month: "short" });
            return formattedDate;
        });
        setTickDates(tickDates);
    };

    const handleSliderChange = (event) => {
        const value = parseInt(event.target.value);
        setSliderValue(value);
        // Llama a la función de devolución de llamada en el componente padre
        onSliderValueChange(value);
    };

    const hourDiff = (intervalEnd - intervalIni) / (1000 * 60 * 60); // Calculate the hour difference
    const widthPercentage = (hourDiff / (24 * (ticksNumber - 1))) * 100; // Convert the hour difference to a percentage

    return (
        <div className={styles.timeSliderContainer}>
            <input
                type="range"
                min={0}
                max={24 * (ticksNumber - 1)}
                value={sliderValue}
                onChange={handleSliderChange}
                className={styles.timeSlider}
            />
            <div className={styles.tooltipContainer} >
                <div style={{ transform: `translateX(${sliderValue * 100/(24 * (ticksNumber - 1))}%)` }}  >
                    <div className={styles.tooltipSmall}>
                        {(((sliderValue / 24) - Math.floor(sliderValue / 24)) * 24).toFixed(0).toString()}h
                    </div>
                </div>
            </div>
            <div className={styles.ticksContainer}>
                <div className={styles.ticks}>
                    {tickDates.map((date, index) => (
                        <div className={styles.tickContainer} key={index}>
                            <div className={styles.tick}></div>
                            <div>{date}</div>
                        </div>
                    ))}
                </div>
                <div className={styles.tickNowContainer} >
                    <div className={styles.tickNow} style={{ left: `${Math.min(Math.max(nowXPosition, 0), 1) * 100}%` }} />
                    <div
                        className={styles.matchPeriod}
                        style={{ left: `${intervalXPosition * 100}%`, width: `${widthPercentage}%` }}
                    />
                </div>
            </div>
        </div>
    );
}
export default ImagesTimeSlider;