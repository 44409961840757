import React, { useState, useEffect } from "react";
import styles from "./Corrientes.module.css";
import ImagesTimeSlider from "../Components/ImagesTimeSlider";

const Corrientes = ({ loading, match }) => {

  const [lastModified, setLastModified] = useState("");
  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    // Obtener la fecha de última modificación de la primera imagen
    fetchFirstImageLastModified();
  }, []);

  const fetchFirstImageLastModified = () => {
    // URL del archivo de metadatos
    const metadataUrl = "https://magicteammanager.com/media/currents/93/metadata.json";

    // Realizar la petición para obtener los metadatos
    fetch(metadataUrl)
      .then((response) => response.json())
      .then((data) => {
        const { lastmodified } = data["image_000.png"];
        setLastModified(lastmodified);
      })
      .catch((error) => {
        console.error("Error al obtener la fecha de última modificación:", error);
      });
  };

  const formattedLastModified = new Date(lastModified);

  const handleSliderValueChange = (value) => {
    setSliderValue(value);
  };
  
  const getImageUrl = () => {
    // Ruta base de las imágenes
    const baseUrl = "https://magicteammanager.com/media/currents/93/";
    // Valor del slider formateado con ceros a la izquierda
    const formattedValue = sliderValue.toString().padStart(3, "0");
    // Nombre de archivo de la imagen basado en el valor del slider
    const fileName = `image_${formattedValue}.png`;
    // URL completa de la imagen
    return `${baseUrl}${fileName}`;
  };

  const getHourlyTimestamp = () => {
    const now = new Date();
    const timestamp = now.toISOString().substr(0, 13).replace("T", "-"); // Formato: AAAA-MM-DD-HH
    return timestamp;
  };

  const dateIni = new Date(lastModified);
  dateIni.setHours(0, 0, 0, 0);

  const intervalIni = new Date(`${match?.dateIni} ${match?.timeIni}`);
  const intervalEnd = new Date(`${match?.dateIni} ${match?.timeEnd}`);

  return (
    <div name="Corrientes" className={styles.currents}>
      <a className={styles.imageContainer} href={`${getImageUrl()}?timestamp=${getHourlyTimestamp()}`} target="_blank" rel="noopener noreferrer">
        <img src={`${getImageUrl()}?timestamp=${getHourlyTimestamp()}`} alt="VientoWRF" />
      </a>
      <ImagesTimeSlider dateIni={dateIni} ticksNumber={4} intervalIni = {intervalIni} intervalEnd = {intervalEnd} onSliderValueChange={handleSliderValueChange} />
      <div className={styles.subtitle}>
        <p>Predicción de corrientes</p>
        <p>
          Creado el{" "}
          {formattedLastModified.toLocaleString("es-ES", {
            day: "2-digit",
            month: "long"
          })}{" "} <span>a las  </span>
          {formattedLastModified.toLocaleString("es-ES", {
            hour: "numeric",
            minute: "2-digit",
            hour12: false
          })}
        </p>
      </div>
    </div>
  );
};

export default Corrientes;
