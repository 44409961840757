import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-scroll";
import styles from "./NavBar.module.css";
import { AiOutlineMenu } from "react-icons/ai";
import { useScrollPosition } from "../Hooks/scrollPosition";
import { FaWind } from "react-icons/fa";
import { TbArrowsRight } from "react-icons/tb";
import { TiWaves } from "react-icons/ti";
import { IoMdVideocam } from "react-icons/io";
import { AiOutlineTool } from "react-icons/ai";
import { FaChevronDown } from "react-icons/fa";
import moment from 'moment';
import 'moment/locale/es';

const NavBar = ({
  teamData,
  racecourseData,
  loading,
  selectedTeamIndex,
  setSelectedTeamIndex,
  selectedEventIndex,
  setSelectedEventIndex,
  selectedMatchIndex,
  setSelectedMatchIndex,
  selectedRacecourseIndex,
  setSelectedRacecourseIndex
}) => {

  const [navBarOpen, setNavBarOpen] = useState(false);
  const [windowDimension, setWindowDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [teamListVisible, setTeamListVisible] = useState(false);
  const [eventListVisible, setEventListVisible] = useState(false);
  const [matchListVisible, setMatchListVisible] = useState(false);
  const [racecourseListVisible, setRacecourseListVisible] = useState(false);

  const detectDimension = () => {
    setWindowDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectDimension);
    return () => {
      window.removeEventListener("resize", detectDimension);
    };
  }, []);

  useEffect(() => {
    if (windowDimension.width > 800) {
      setNavBarOpen(false);
    }
  }, [windowDimension]);

  const links = [
    { id: 1, title: "Weather", isTitle: true },
    { id: 2, link: "Viento", icon: <FaWind />, isMeteo: true },
    { id: 3, link: "Olas", icon: <TiWaves />, isMeteo: true },
    { id: 4, link: "Corrientes", icon: <TbArrowsRight />, isMeteo: true },
    { id: 5, link: "WebCams", icon: <IoMdVideocam />, isMeteo: true },
    { id: 6, title: "Performance", isTitle: true },
    { id: 7, link: "Análisis", icon: <IoMdVideocam />, isMeteo: true }
  ];

  const sidebarRef = useRef(null);
  const openSideButton = useRef(null);
  const teamListContainerRef = useRef(null);
  const eventListContainerRef = useRef(null);
  const matchListContainerRef = useRef(null);
  const racecourseListContainerRef = useRef(null);
  const teamContainerRef = useRef(null);
  const eventContainerRef = useRef(null);
  const matchContainerRef = useRef(null);

  const handleClickOutsideSidebar = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      openSideButton.current &&
      !openSideButton.current.contains(event.target)
    ) {
      setNavBarOpen(false);
      setTeamListVisible(false);
      setEventListVisible(false);
      setMatchListVisible(false);
      setRacecourseListVisible(false);
    }
  };

  const handleClickOutsideTeamList = (event) => {
    if (
      teamListContainerRef.current &&
      !teamListContainerRef.current.contains(event.target) &&
      teamContainerRef.current &&
      !teamContainerRef.current.contains(event.target)
    ) {
      setTeamListVisible(false);
    }
  };


  const handleClickOutsideEventList = (event) => {
    if (
      eventListContainerRef.current &&
      !eventListContainerRef.current.contains(event.target) &&
      eventContainerRef.current &&
      !eventContainerRef.current.contains(event.target)
    ) {
      setEventListVisible(false);
    }
  };

  const handleClickOutsideMatchList = (event) => {
    if (
      matchListContainerRef.current &&
      !matchListContainerRef.current.contains(event.target) &&
      matchContainerRef.current &&
      !matchContainerRef.current.contains(event.target)
    ) {
      setMatchListVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideSidebar);
    document.addEventListener("mousedown", handleClickOutsideTeamList);
    document.addEventListener("mousedown", handleClickOutsideEventList);
    document.addEventListener("mousedown", handleClickOutsideMatchList);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSidebar);
      document.removeEventListener("mousedown", handleClickOutsideTeamList);
      document.removeEventListener("mousedown", handleClickOutsideEventList);
      document.removeEventListener("mousedown", handleClickOutsideMatchList);
    };
  }, []);


  const scrollPosition = useScrollPosition();

  const daystoevent = (event) => {
    const dateIni = moment(event.dateIni);
    const today = moment();
    let days = dateIni.diff(today, 'days');
    days = days === 0 ? 'Hoy' : days === 1 ? 'Mañana' : ' en ' + days + ' días';
    return days;
  };

  return (
    <div className={scrollPosition > 0 ? styles.navOnScroll : styles.navBar}>
      {/* BARRA SUPERIOR */}
      <div className={styles.row1}>
        <div className={styles.outlineMenu} ref={openSideButton}>
          <AiOutlineMenu
            color="#100e8d"
            onClick={() => setNavBarOpen(!navBarOpen)}
            size={25}
          />
        </div>
        <div>
          <a
            className={styles.logo}
            href="http://https://magicteammanager.rf.gd"
          >
          <span>MAGIC Team Manager</span>
          <span style={{fontWeight: '10', marginLeft: '2px'}}>.weather</span>
          </a>
        </div>
        {windowDimension.width > 800 && (
          <ul className={styles.topLinksContainer}>
            {links.map(({ link, id, isMeteo }) => (
              <div key={id}>
                {isMeteo && (
                  <Link
                    onClick={() => setNavBarOpen(false)}
                    to={link}
                    smooth
                    duration={500}
                    className={styles.navLink}
                  >
                    {link}
                  </Link>
                )}
              </div>
            ))}
          </ul>
        )}
      </div>
      <div className={`${styles.row2} ${navBarOpen ? styles.slideUp : ""}`}>
        <div className={styles.directo}>Campo {racecourseData[selectedRacecourseIndex]?.racecourseName}, directo</div>
      </div>

      {/* BARRA LATERAL */}
      <div
        ref={sidebarRef}
        className={`${styles.lateralBar} ${navBarOpen ? styles.slideLeft : styles.slideRight
          }`}
      >
        <ul className={styles.lateralBarUl}>
          {/* EQUIPO */}
          <div ref={teamContainerRef}
            className={styles.teamContainer}
            onClick={() => setTeamListVisible(!teamListVisible)}
          >
            <div className={styles.team}>
              {teamData[selectedTeamIndex]?.teamName}
            </div>
            {teamListVisible && (
              <div className={styles.teamListContainer} ref={teamListContainerRef}>
                <ul className={styles.teamList}>
                  {teamData.map((team, index) => (
                    <li
                      key={index}
                      className={selectedTeamIndex === index ? styles.selected : ""}
                      onClick={() => {
                        setSelectedTeamIndex(index);
                        setTeamListVisible(false);
                      }}
                    >
                      {team.teamName}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* EVENTOS */}
          <div className={styles.title} style={{ marginLeft: '10px' }}>Próximas regatas</div>
          <div className={styles.eventContainer}>
            <div ref={eventContainerRef}
              className={styles.matchContainer}
              onClick={() => setEventListVisible(!eventListVisible)}
            >
              <div className={styles.eventName}>
                {teamData[selectedTeamIndex]?.events[selectedEventIndex]?.shortName}
              </div>
              <div className={`${styles.icon} icon`}>
                <FaChevronDown />
              </div>
            </div>
            {eventListVisible && (
              <div className={styles.eventListContainer} ref={eventListContainerRef}>
                <ul className={styles.eventList}>
                  {teamData[selectedTeamIndex]?.events
                    .filter(event => event.upcoming > 0)
                    .sort((a, b) => a.upcoming - b.upcoming)
                    .map((event) => {
                      const indexInEvents = teamData[selectedTeamIndex]?.events.findIndex(e => e.eventId === event.eventId);
                      return (
                        <li
                          key={event.eventId}
                          className={selectedEventIndex === indexInEvents ? styles.selected : ""}
                          onClick={() => {
                            setSelectedEventIndex(indexInEvents);
                            setEventListVisible(false);
                          }}
                        >
                          <div>{event.shortName}</div>
                          <div className={styles.faltan}>{daystoevent(event)}</div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
            <hr className={styles.eventSeparator}></hr>

            <div ref={matchContainerRef}
              className={styles.eventNameContainer}
              onClick={() => setMatchListVisible(!matchListVisible)}
            >
              <div className={styles.matchContainer}>
                <div className={styles.matchName}>
                  {teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[selectedMatchIndex]?.matchName}
                </div>
                <div className={`${styles.icon} icon`}>
                  <FaChevronDown />
                </div>
              </div>
              <div className={styles.matchDate}>
                {racecourseData[racecourseData?.findIndex(racecourse => racecourse.racecourseId === teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[selectedMatchIndex]?.racecourseId)]?.racecourseName},
                {moment(teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[selectedMatchIndex]?.dateIni).format(" D MMM")}
              </div>
            </div>
            {matchListVisible && (
              <div className={styles.matchListContainer} ref={matchListContainerRef}>
                <ul className={styles.matchList}>
                  {teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs.map((match, index) => (
                    <li
                      key={index}
                      className={selectedMatchIndex === index ? styles.selected : ""}
                      onClick={() => {
                        setSelectedMatchIndex(index);
                        setSelectedRacecourseIndex(racecourseData?.findIndex(racecourse => racecourse.racecourseId === teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[index]?.racecourseId));
                        setMatchListVisible(false);
                      }}
                    >
                      <div>{match.matchName}</div>
                      <div className={styles.matchDate}>
                        {racecourseData[racecourseData.findIndex(racecourse => racecourse.racecourseId === teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[index]?.racecourseId)]?.racecourseName},
                        {moment(teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[index]?.dateIni).format(" D MMM")}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* CAMPOS */}
          <div className={styles.title} >Campo</div>
          <div className={styles.racecourseContainer}>
            <div className={styles.racecourseNameContainer}
              onClick={() => setRacecourseListVisible(!racecourseListVisible)}
            >
              <div className={styles.racecourseName}>
                {racecourseData[selectedRacecourseIndex]?.racecourseName}
              </div>
              <div className={`${styles.icon} icon`}>
                <FaChevronDown />
              </div>
            </div>
            {racecourseListVisible && (
              <div className={styles.racecourseListContainer} ref={racecourseListContainerRef}>
                <ul className={styles.racecourseList}>
                  {racecourseData.map((racecourse, index) => (
                    <li
                      key={index}
                      className={selectedRacecourseIndex === index ? styles.selected : ""}
                      onClick={() => {
                        setSelectedRacecourseIndex(index);
                        setRacecourseListVisible(false);
                      }}
                    >
                      {racecourse.racecourseName}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* MAPAS */}
          <div className={styles.mapContainer}>
            <div className={styles.map}>
              <a
                href={racecourseData[selectedRacecourseIndex]?.mapImageUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.map}
              >
                <img
                  src={racecourseData[selectedRacecourseIndex]?.mapImageUrl}
                  alt="not found"
                  style={{ scale: `${racecourseData[selectedRacecourseIndex]?.mapImageScale}` }} />
              </a>
            </div>
          </div>

          {/* METEO */}
          {links.map(({ id, title, link, icon, isTitle, arrows, element }) => (
            <div key={id}>
              {isTitle ? (
                <div className={styles.title}>{title}</div>
              ) : (
                <div>
                  <div className={styles.latLinksContainer}>
                    <Link
                      onClick={() => setNavBarOpen(false)}
                      to={link}
                      smooth
                      duration={500}
                      className={styles.navLink}
                    >
                      <div className={`${styles.icon} icon`}>{icon}</div>
                      {link}
                    </Link>
                    {/*<div className={styles.arrows}>
                      {arrows === "horiz" ? (
                        <div className={styles.arrowsContainer}>
                          <div
                            className={styles.arrow}
                            onClick={() => {
                              if (element === teamData) {
                                setSelectedTeamIndex((prevIndex) =>
                                  prevIndex > 0 ? prevIndex - 1 : (element?.length || 0) - 1
                                );
                              } else if (
                                element === teamData[selectedTeamIndex]?.events
                              ) {
                                setSelectedEventIndex((prevIndex) =>
                                  prevIndex > 0 ? prevIndex - 1 : (element?.length || 0) - 1
                                );
                              } else if (
                                element ===
                                teamData[selectedTeamIndex]?.events[
                                  selectedEventIndex
                                ]?.matchs
                              ) {
                                setSelectedMatchIndex((prevIndex) =>
                                  prevIndex > 0 ? prevIndex - 1 : (element?.length || 0) - 1
                                );
                              }
                            }}
                          >
                            &lt;
                          </div>
                          <div
                            className={styles.arrow}
                            onClick={() => {
                              if (element === teamData) {
                                setSelectedTeamIndex((prevIndex) =>
                                  prevIndex < (element?.length || 0) - 1 ? prevIndex + 1 : 0
                                );
                              } else if (
                                element === teamData[selectedTeamIndex]?.events
                              ) {
                                setSelectedEventIndex((prevIndex) =>
                                  prevIndex < (element?.length || 0) - 1 ? prevIndex + 1 : 0
                                );
                              } else if (
                                element ===
                                teamData[selectedTeamIndex]?.events[
                                  selectedEventIndex
                                ]?.matchs
                              ) {
                                setSelectedMatchIndex((prevIndex) =>
                                  prevIndex < (element?.length || 0) - 1 ? prevIndex + 1 : 0
                                );
                              }
                            }}
                          >
                            &gt;
                          </div>
                        </div>
                          ) : null}
                    </div>*/}
                  </div>
                </div>
              )}
            </div>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
