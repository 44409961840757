import React, { useEffect, useState } from "react";
import styles from "./VientosMar.module.css";
import { AiOutlineLineChart } from "react-icons/ai";
import { FaCircle } from "react-icons/fa"; // Importa el ícono de círculo
import moment from "moment-timezone";

const VientosMar = ({ loading, windsonsea }) => {
  const [dataReceived, setdataReceived] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  useEffect(() => {
    // Establecer dataReceived en true si hay datos de vientos
    if (!dataReceived && Array.isArray(windsonsea) && windsonsea.length > 0) {
      setdataReceived(true);
    }
  }, [dataReceived, windsonsea]);

  const getIntensityColor = (intensity) => {
    if (intensity >= 140) return "#FED9EF";
    if (intensity >= 130) return "#FD9EE0";
    if (intensity >= 120) return "#FE0096";
    if (intensity >= 110) return "#C80064";
    if (intensity >= 100) return "#B40034";
    if (intensity >= 95) return "#AA001D";
    if (intensity >= 90) return "#AA001D";
    if (intensity >= 85) return "#C93334";
    if (intensity >= 80) return "#DC4A1D";
    if (intensity >= 75) return "#E67D00";
    if (intensity >= 70) return "#E67D00";
    if (intensity >= 65) return "#FE9600";
    if (intensity >= 60) return "#FEAE00";
    if (intensity >= 56) return "#FEC800";
    if (intensity >= 53) return "#FEE100";
    if (intensity >= 50) return "#FFE000";
    if (intensity >= 46) return "#01FB00";
    if (intensity >= 43) return "#00F028";
    if (intensity >= 40) return "#00E650";
    if (intensity >= 36) return "#00E677";
    if (intensity >= 33) return "#00E6A1";
    if (intensity >= 30) return "#00E6FE";
    if (intensity >= 26) return "#00C8FE";
    if (intensity >= 23) return "#0096FE";
    if (intensity >= 20) return "#0064FE";
    if (intensity >= 18) return "#0032FE";
    if (intensity >= 16) return "#3200FE";
    if (intensity >= 14) return "#6400FE";
    if (intensity >= 12) return "#9600FE";
    if (intensity >= 10) return "#C800FE";
    if (intensity >= 8) return "#FA00FE";
    if (intensity >= 6) return "#C800C8";
    if (intensity >= 4) return "#960096";
    if (intensity >= 2) return "#640064";
    if (intensity >= 0) return "#360038";
  };

  const getStatusColor = (estacion) => {
    const currentTime = moment();
    const captureMoment = moment.utc(estacion.windsonlandcapture).tz("Europe/Madrid");
    const minutesDifference = currentTime.diff(captureMoment, "minutes");

    if (minutesDifference > 60) {
      return "red";
    } else if (minutesDifference > 20) {
      return "yellow";
    } else {
      return "green";
    }
  };

  const updateStatusIcon = (estacion) => (
    <FaCircle
      style={{
        color: getStatusColor(estacion),
        width: "10px", // Tamaño deseado
        height: "10px", // Tamaño deseado
        borderRadius: "50%", // Hace el icono redondo
        marginRight: "3px",
        border: "1px solid black",
      }}
    />
  );

  return (
    <div name="Viento" className={styles.vientos}>
      <p>Viento</p>
      {dataReceived && (
        <div>
          {windsonsea?.map((estacion, index) => {
            const intensityColor = getIntensityColor(estacion.windsonseaintensity);
            const isRowExpanded = expandedRow === index; // Verificar si esta fila está expandida
            const toggleRowExpansion = () => {
              setExpandedRow(isRowExpanded ? null : index);
            };
            return (
              <div className={styles.windContainer}>
                <React.Fragment key={estacion.windsonseaname}>
                  <table className={styles.table}>
                    <tbody>
                      <tr onClick={toggleRowExpansion}>
                        <td className={styles.boxHeader}>
                          <div>{estacion.windsonseaname}</div>
                          <div style={{ fontSize: '10px' }}>
                            <span style={{ marginRight: '5px', color: 'lightGray' }}>
                              {moment.utc(estacion.windsonseacapture).tz("Europe/Madrid").format("D MMM H:mm")}
                            </span>
                            {updateStatusIcon(estacion)}
                          </div>
                        </td>
                      </tr>
                      <tr onClick={toggleRowExpansion}>
                        <td>
                          <table style={{ width: '100%' }}>
                            <tbody>
                              <td style={{ minWidth: '40%', width: '40%', fontSize: '14px', color: 'grey' }}>
                                Viento 10':
                              </td>
                              <td style={{ minWidth: '30%', width: '30%', textAlign: 'center' }}>
                                {estacion.windsonseadirection !== null && parseFloat(estacion.windsonseadirection) !== 0 ? (
                                  <a
                                    href={`http://www2.meteogalicia.gal/galego/observacion/plataformas/contidos/graficas48horas.asp?pest=${estacion.windsonseaId}&pparam=82`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.tableValue}
                                  >
                                    <span className={styles.tableValue}>
                                      {(() => {
                                        const originalValue = estacion.windsonseadirection.toString(); // Convertir a cadena para trabajar con los dígitos
                                        const primeraParte = originalValue.substring(0, originalValue.length - 1).toString().padStart(1, '0');;
                                        const segundaParte = originalValue.slice(-1);
                                        return (
                                          <>
                                            <span>{primeraParte}</span>
                                            <span style={{ fontSize: '0.9em', marginLeft: '2px' }}>{segundaParte}°</span>
                                          </>
                                        );
                                      })()}
                                    </span>
                                    <AiOutlineLineChart className={styles.tableIcon} />
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td style={{ minWidth: '30%', width: '30%', textAlign: 'center' }}>
                                {estacion.windsonseaintensity !== null && parseFloat(estacion.windsonseaintensity) !== 0 ? (
                                  <a
                                    href={`http://www2.meteogalicia.gal/galego/observacion/plataformas/contidos/graficas48horas.asp?pest=${estacion.windsonseaId}&pparam=81`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={styles.tableValue}
                                    style={{ color: intensityColor }}
                                  >
                                    <span className={styles.tableValue}>
                                      {(() => {
                                        const originalValue = estacion.windsonseaintensity * 1.94384;
                                        const parteEntera = Math.floor(originalValue);
                                        const parteDecimal = (originalValue - parteEntera).toFixed(1);
                                        return (
                                          <>
                                            <span >{parteEntera}</span>
                                            <span style={{ fontSize: '0.8em' }}>.{parteDecimal.substring(2)}</span>
                                            <span style={{ fontSize: '0.5em' }}> kn</span>
                                          </>
                                        );
                                      })()}
                                    </span>
                                    <AiOutlineLineChart className={styles.tableIcon} />
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      {isRowExpanded && (
                        <tr >
                          <td >
                            <table style={{ width: '100%' }}>
                              <tbody >
                                <tr>
                                  <td style={{ minWidth: '40%', width: '40%', fontSize: '14px', color: 'grey'}}>
                                    Rachas 10':
                                  </td>
                                  <td style={{ minWidth: '30%', width: '30%', textAlign: 'center' }}>
                                    {estacion.windsonseagustdir !== null && parseFloat(estacion.windsonseagustdir) !== 0 ? (
                                      <a
                                        href={`http://www2.meteogalicia.gal/galego/observacion/plataformas/contidos/graficas48horas.asp?pest=${estacion.windsonseaId}&pparam=10003`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={styles.tableValue}
                                      >
                                        <span className={styles.tableValue}>
                                          {(() => {
                                            const originalValue = estacion.windsonseagustdir.toString(); // Convertir a cadena para trabajar con los dígitos
                                            const primeraParte = originalValue.substring(0, originalValue.length - 1).toString().padStart(1, '0');;
                                            const segundaParte = originalValue.slice(-1);
                                            return (
                                              <>
                                                <span style={{ fontSize: '0.7em' }}>{primeraParte}</span>
                                                <span style={{ fontSize: '0.6em', marginLeft: '2px' }}>{segundaParte}°</span>
                                              </>
                                            );
                                          })()}
                                        </span>
                                        <AiOutlineLineChart className={styles.tableIcon} />
                                      </a>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td style={{ minWidth: '30%', width: '30%', textAlign: 'center' }}>
                                    {estacion.windsonseagustint !== null && parseFloat(estacion.windsonseagustint) !== 0 ? (
                                      <a
                                        href={`http://www2.meteogalicia.gal/galego/observacion/plataformas/contidos/graficas48horas.asp?pest=${estacion.windsonseaId}&pparam=10015`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={styles.tableValue}
                                        style={{ color: intensityColor }}
                                      >
                                        <span className={styles.tableValue}>
                                          {(() => {
                                            const originalValue = estacion.windsonseagustint * 1.94384;
                                            const parteEntera = Math.floor(originalValue);
                                            const parteDecimal = (originalValue - parteEntera).toFixed(1);
                                            return (
                                              <>
                                                <span style={{ fontSize: '0.7em' }}>{parteEntera}</span>
                                                <span style={{ fontSize: '0.5em' }}>.{parteDecimal.substring(2)} kn</span>
                                              </>
                                            );
                                          })()}
                                        </span>
                                        <AiOutlineLineChart className={styles.tableIcon} />
                                      </a>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  {(estacion.windsonseaairtemp !== null && estacion.windsonseaseatemp !== null) ? (
                                    <>
                                      <td style={{ minWidth: '40%', width: '40%', fontSize: '14px', color: 'grey'}}>T. aire-agua:</td>
                                      <td style={{ minWidth: '30%', width: '30%', textAlign: 'center' }}>
                                        {(estacion.windsonseaairtemp * 1).toFixed(1)}°-{(estacion.windsonseaseatemp * 1).toFixed(1)}°{" "}

                                      </td>
                                      <td style={{ minWidth: '30%', width: '30%', textAlign: 'center' }}>
                                        <span> &#40;</span>
                                        {estacion.windsonseaairtemp - estacion.windsonseaseatemp >= 0 ? "+" : "-"}{Math.abs(estacion.windsonseaairtemp - estacion.windsonseaseatemp).toFixed(1)}
                                        <span>°&#41;</span>
                                      </td>
                                    </>
                                  ) : (
                                    <td>-</td>
                                  )}
                                </tr>

                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </React.Fragment>
              </div>
            );
          })}
        </div>
      )}

      <div className={styles.subtitle}>
        Observación del viento en estaciones en el mar
      </div>
    </div >
  );
};

export default VientosMar;
