/*import React, { useEffect, useRef } from 'react';
import styles from "./WebcamsVideo.module.css";
import Hls from 'hls.js';

const WebcamsVideo = ({ loading, webcamUrls, webcamNames }) => {
  const videoRefs = useRef([]);

  useEffect(() => {
    const initializePlayer = (videoRef, videoUrl) => {
      let hls;
      if (videoRef.current) {
        if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
          videoRef.current.src = videoUrl;
          videoRef.current.play().catch(error => {
            console.log('Failed to play video:', error);
          });
        } else if (Hls.isSupported()) {
          hls = new Hls();
          hls.loadSource(videoUrl);
          hls.attachMedia(videoRef.current);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            videoRef.current.play().catch(error => {
              console.log('Failed to play video:', error);
            });
          });
        }
      }
      return hls;
    };

    const hlsInstances = [];

    if (Array.isArray(webcamUrls) && Array.isArray(webcamNames)) {
      webcamUrls.forEach((videoUrl, index) => {
        if (!videoRefs.current[index]) {
          videoRefs.current[index] = React.createRef();
        }

        const videoRef = videoRefs.current[index];
        const hlsInstance = initializePlayer(videoRef, videoUrl);
        hlsInstances.push(hlsInstance);
      });
    }

    return () => {
      hlsInstances.forEach((hlsInstance) => {
        if (hlsInstance) {
          hlsInstance.destroy();
        }
      });
    };
  }, [webcamUrls, webcamNames]);

  return (
    <div name="WebCams" className={styles.webcams}>
      <p>Webcams</p>
      {Array.isArray(webcamUrls) && Array.isArray(webcamNames) ? (
        webcamUrls.map((videoUrl, index) => (
          <div key={index} className={styles.videoContainer}>
            <video
              autoPlay
              controls
              muted
              playsInline
              className={styles.videoPlayer}
              ref={videoRefs.current[index]}
            ></video>
            <div className={styles.subtitle}>
              Webcam en {webcamNames[index]}
            </div>
          </div>
        ))
      ) : (
        <p>No hay datos de webcam disponibles.</p>
      )}
    </div>
  );
};

export default WebcamsVideo;*/



import React, { useEffect, useRef } from 'react';
import styles from "./WebcamsVideo.module.css";
import Hls from 'hls.js';

const WebcamsVideo = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    let hls;

    const initializePlayer = () => {
      if (videoRef.current) {
        if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
          videoRef.current.src = 'https://www.crtvg.es:1556/hls/mvigo/index.m3u8';
        } else if (Hls.isSupported()) {
          hls = new Hls();
          hls.loadSource('https://www.crtvg.es:1556/hls/mvigo/index.m3u8');
          hls.attachMedia(videoRef.current);
        }
      }
    };

    initializePlayer();

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, []);

  return (
    <div name="WebCams" className={styles.webcams}>
      <p>Webcams</p>
      <video
        autoPlay
        controls
        muted
        playsInline
        className={styles.videoPlayer}
        ref={videoRef}
      ></video>
      <div className={styles.subtitle}>
        Webcam en Vigo
      </div>
    </div>
  );
}

export default WebcamsVideo;