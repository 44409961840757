import React, { useEffect, useState } from "react";
import styles from "./Mareas.module.css";

const Mareas = ({ loading, tidestation, tides }) => {
  const [tablaCreada, setTablaCreada] = useState(false);

  useEffect(() => {
    // Establecer tablaCreada en true si hay datos de mareas
    if (!tablaCreada && Array.isArray(tides) && tides.length > 0) {
      setTablaCreada(true);
    }
  }, [tablaCreada, tides]);

  // Obtener la fecha actual
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses en JavaScript son base 0, por lo que se suma 1
  const year = today.getFullYear();

  // Formatear la fecha y hora en formato (d) hh:mm
  const formatDateTime = (fechaHora) => {
    const date = new Date(fechaHora);
    const day = date.getDate();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `(${day}) ${hours}:${minutes}`;
  };

  return (
    <div className={styles.mareas}>
      <div className={styles.tableContainer}>
        {tablaCreada && (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Estado</th>
                <th>Hora</th>
                <th>Altura (m)</th>
              </tr>
            </thead>
            <tbody>
              {tides?.map((marea) => (
                <tr key={marea.hora}>
                  <td>{marea.estado}</td>
                  <td>{formatDateTime(marea.hora)}</td>
                  <td>{marea.altura}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className={styles.subtitle}>
        Tabla de mareas para {tidestation}
      </div>
      <div className={styles.ctaContainer}>
        <a
          className={styles.callToAction}
          href={`https://www.meteogalicia.gal/web/predicion/maritima/graficaMareas.action?idPuerto=3&data=${day}/${month}/${year}&periodo=1`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver en Meteogalicia
        </a>
      </div>
    </div>
  );
};

export default Mareas;
