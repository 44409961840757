import React, { useState } from "react";
import styles from "./Analisis.module.css";

const Analisis = () => {
    const [csvHeader, setCsvHeader] = useState("");

    const handleFileChange = (file) => {
        console.log("Selected file:", file);
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const contents = event.target.result;
                sendDataToServer(contents); // Enviar el contenido del CSV al servidor
            };
            reader.readAsText(file);
        }
    };
    
    const sendDataToServer = async (csvContent) => {
        try {
            const response = await fetch("https://magicteammanager.com/telemetryToDB.php", {
                method: "POST",
                headers: {
                    "Content-Type": "text/plain",
                },
                body: csvContent,
            });
            const data = await response.text(); // El servidor probablemente enviará una respuesta de texto
            console.log(data); // Respuesta del servidor
        } catch (error) {
            console.error("Error al enviar los datos al servidor", error);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        console.log("Dropped file:", file);
        handleFileChange(file);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div name="Análisis" className={styles.analisis}>
            <p className={styles.title}>Análisis</p>
            <div
                className={styles.rectangle}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
            >
                <div className={styles.dashedLine}></div>
                <p className={styles.centeredText}>
                    <p>Arrastra aquí el archivo *.csv para cargar la telemetría de la jornada</p>
                    <p>o</p>
                </p>
                <div className={styles.button_area}>
                    <label className={styles.blueButton} htmlFor="fileInput">
                        Selecciona el archivo
                    </label>
                    <input
                        type="file"
                        id="fileInput"
                        accept=".csv"
                        onChange={(e) => handleFileChange(e.target.files[0])}
                    />
                </div>
                <p>CSV Header: {csvHeader}</p>
            </div>
        </div>
    );
};

export default Analisis;
