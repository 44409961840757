import React, { useEffect, useState, useRef } from "react";
import NavBar from "./Components/NavBar";
import Frentes from "./Screens/Frentes";
import Mareas from "./Screens/Mareas";
import VientosMar from "./Screens/VientosMar";
import VientosTierra from "./Screens/VientosTierra";
import VientoWRF from "./Screens/VientoWRF";
import Corrientes from "./Screens/Corrientes";
import Olas from "./Screens/Olas";
import WebcamsVideo from "./Screens/WebcamsVideo";
import WebcamsImages from "./Screens/WebcamsImages";
import Analisis from "./Screens/Analisis";

// Hook personalizado para intervalo con función de limpieza
function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const intervalId = setInterval(tick, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
}

function App() {
  const [teamData, setTeamData] = useState([]);
  let [racecourseData, setRacecourseData] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedTeamIndex, setSelectedTeamIndex] = useState(0);
  const [selectedEventIndex, setSelectedEventIndex] = useState(null);
  const [selectedMatchIndex, setSelectedMatchIndex] = useState(null);
  const [selectedRacecourseIndex, setSelectedRacecourseIndex] = useState(0);

  if (typeof racecourseData === 'object' && !Array.isArray(racecourseData)) {
    racecourseData = Object.values(racecourseData);
  }

  const fetchTeamData = async () => {
    try {
      const response = await fetch('https://magicteammanager.com/apiteam.php');
      const data = await response.json();
      setTeamData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRacecourseData = async () => {
    try {
      const response = await fetch('https://magicteammanager.com/apiracecourses.php');
      const data = await response.json();
      setRacecourseData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeamData();
    fetchRacecourseData();
  }, []);

  useEffect(() => {
    const firstUpcomingEventIndex = teamData[selectedTeamIndex]?.events.findIndex(event => parseInt(event.upcoming) === 1);
    if (firstUpcomingEventIndex !== -1) {
      setSelectedEventIndex(firstUpcomingEventIndex);

      const firstUpcomingMatchIndex = teamData[selectedTeamIndex]?.events[firstUpcomingEventIndex]?.matchs.findIndex(match => parseInt(match.upcoming) === 1);
      if (firstUpcomingMatchIndex !== -1) {
        setSelectedMatchIndex(firstUpcomingMatchIndex);
        setSelectedRacecourseIndex(racecourseData.findIndex(r => r.racecourseId === teamData[selectedTeamIndex]?.events[firstUpcomingEventIndex]?.matchs[firstUpcomingMatchIndex]?.racecourseId));
      } else {
        setSelectedMatchIndex(0);
        setSelectedRacecourseIndex(racecourseData.findIndex(r => r.racecourseId === teamData[selectedTeamIndex]?.events[firstUpcomingEventIndex]?.matchs[0]?.racecourseId));
      }
    }
  }, [teamData, racecourseData, selectedTeamIndex]);

  useEffect(() => {
    const firstUpcomingMatchIndex = teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs.findIndex(match => parseInt(match.upcoming) === 1);
    if (firstUpcomingMatchIndex !== -1) {
      setSelectedMatchIndex(firstUpcomingMatchIndex);
      setSelectedRacecourseIndex(racecourseData.findIndex(r => r.racecourseId === teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[firstUpcomingMatchIndex]?.racecourseId));
    } else {
      setSelectedMatchIndex(0);
      setSelectedRacecourseIndex(racecourseData.findIndex(r => r.racecourseId === teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[0]?.racecourseId));
    }
  }, [teamData, racecourseData, selectedEventIndex]);

  useEffect(() => {
    setSelectedRacecourseIndex(racecourseData.findIndex(r => r.racecourseId === teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[selectedMatchIndex]?.racecourseId));
  }, [teamData, racecourseData, selectedMatchIndex]);

  useInterval(() => {
    fetchTeamData();
    fetchRacecourseData();
  }, 1 * 60 * 1000);

  return (
    <div className="App">
      <NavBar
        teamData={teamData}
        racecourseData={racecourseData}
        loading={loading}
        selectedTeamIndex={selectedTeamIndex}
        setSelectedTeamIndex={setSelectedTeamIndex}
        selectedEventIndex={selectedEventIndex}
        setSelectedEventIndex={setSelectedEventIndex}
        selectedMatchIndex={selectedMatchIndex}
        setSelectedMatchIndex={setSelectedMatchIndex}
        selectedRacecourseIndex={selectedRacecourseIndex}
        setSelectedRacecourseIndex={setSelectedRacecourseIndex}
      />
      <VientosMar
        loading={loading}
        windsonsea={racecourseData[selectedRacecourseIndex]?.windsonsea}
      />
      <VientosTierra
        loading={loading}
        windsonland={racecourseData[selectedRacecourseIndex]?.windsonland}
      />
      <VientoWRF
        loading={loading}
        match={teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[selectedMatchIndex]}
      />
      <Frentes />
      <Corrientes
        loading={loading}
        match={teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[selectedMatchIndex]}
      />
      <Mareas
        loading={loading}
        tidestation={racecourseData[selectedRacecourseIndex]?.tidesstation}
        tides={racecourseData[selectedRacecourseIndex]?.tides}
      />
      <Olas
        loading={loading}
        match={teamData[selectedTeamIndex]?.events[selectedEventIndex]?.matchs[selectedMatchIndex]}
      />
      <WebcamsVideo
        loading={loading}
        webcamUrls={racecourseData[selectedRacecourseIndex]?.webcamsvideo?.map(webcam => webcam.webcamvideourl)}
        webcamNames={racecourseData[selectedRacecourseIndex]?.webcamsvideo?.map(webcam => webcam.webcamvideoname)}
      />
      <WebcamsImages
        loading={loading}
        webcamUrls={racecourseData[selectedRacecourseIndex]?.webcamsimage?.map(webcam => webcam.webcamimageurl)}
        webcamNames={racecourseData[selectedRacecourseIndex]?.webcamsimage?.map(webcam => webcam.webcamimagename)}
      />
      <Analisis />
    </div>
  );
}

export default App;

