import React, { useEffect, useState } from "react";
import styles from "./WebcamsImages.module.css";

const WebcamsImages = ({ loading, webcamUrls, webcamNames }) => {
  const [webcamImages, setWebcamImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (webcamUrls && webcamUrls.length > 0) {
      setWebcamImages(webcamUrls);
      setIsLoading(false);
    }
  }, [webcamUrls]);

  useEffect(() => {
    const refreshInterval = setInterval(() => {
      setWebcamImages((prevImages) =>
        prevImages.map((imageUrl) => `${imageUrl}?timestamp=${Date.now()}`)
      );
    }, 30000); // 30000 milliseconds = 0.5 minute

    return () => clearInterval(refreshInterval);
  }, [webcamUrls]);

  useEffect(() => {
    if (webcamUrls && webcamUrls.length === 0) {
      setWebcamImages([]);
      setIsLoading(false);
    }
  }, [webcamUrls]);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div name="WebcamsImage" className={styles.webcams}>
      {loading ? (
        <span className={styles.loadingText}>Cargando webcams...</span>
      ) : (
        webcamImages.map((imageUrl, index) => (
          <div key={index}>
            <a
              href={imageUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.webImage}
                src={imageUrl}
                alt={webcamNames && webcamNames[index]}
                onLoad={handleImageLoad}
              />
            </a>
            <div className={styles.subtitle}>
              Webcam de {webcamNames && webcamNames[index]}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default WebcamsImages;
