import React, { useEffect, useState } from "react";
import styles from "./Frentes.module.css";
import { Range, getTrackBackground } from "react-range";

const Frentes = () => {
  const [urls, setUrls] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [currentFrame, setCurrentFrame] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const frameRate = 1000; // Intervalo de tiempo en milisegundos entre cada frame

  useEffect(() => {
    const obtenerUrlsFrentes = async () => {
      try {
        const urls = [
          "https://magicteammanager.com/media/frentes/frentes00.gif",
          "https://magicteammanager.com/media/frentes/frentes12.gif",
          "https://magicteammanager.com/media/frentes/frentes24.gif",
          "https://magicteammanager.com/media/frentes/frentes36.gif",
          "https://magicteammanager.com/media/frentes/frentes48.gif",
          "https://magicteammanager.com/media/frentes/frentes60.gif",
          "https://magicteammanager.com/media/frentes/frentes72.gif",
        ];
    
        // Generar marca de tiempo actualizada cada hora
        const timestamp = Math.floor(Date.now() / (60 * 60 * 1000)); // Dividir por milisegundos en una hora
    
        // Agregar cadena de consulta con la marca de tiempo a cada URL de imagen
        const updatedUrls = urls.map((url) => `${url}?timestamp=${timestamp}`);
    
        setUrls(updatedUrls);
        setIsLoading(false);
      } catch (error) {
        console.error("Error al obtener las URLs de los frentes:", error);
        setIsError(true);
      }
    };
    obtenerUrlsFrentes();
  }, []);

  useEffect(() => {
    if (isPlaying) {
      const intervalId = setInterval(() => {
        setCurrentFrame((prevFrame) => (prevFrame + 1) % urls.length);
      }, frameRate);
      return () => clearInterval(intervalId);
    }
  }, [isPlaying, urls.length, frameRate]);

  const handleRetry = () => {
    setIsLoading(true);
    setIsError(false);
  };

  const handlePlay = () => {
    setIsPlaying(!isPlaying);
  };

  const handleChangeFrame = (value) => {
    setCurrentFrame(value[0]);
  };

  const formatTooltip = (value) => {
    return `${value} Frame${value !== 1 ? "s" : ""}`;
  };

  return (
    <div className={styles.frentes}>
      {isLoading ? (
        <div>Cargando...</div>
      ) : isError ? (
        <button onClick={handleRetry}>Reintentar</button>
      ) : (
        <div>
          <div className={styles.playerContainer}>
            <a
              href={urls[currentFrame]}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => window.open(urls[currentFrame], "_blank")}
            >
              <img
                src={urls[currentFrame]}
                alt="Frente"
                className={styles.image}
              />
            </a>
          </div>
          <div className={styles.controls}>
            <button onClick={handlePlay}>{isPlaying ? "Pause" : "Play"}</button>
            <div className={styles.rangeSlider}>
              <Range
                step={1}
                min={0}
                max={urls.length - 1}
                values={[currentFrame]}
                onChange={handleChangeFrame}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    className={styles.track}
                    style={{
                      ...props.style,
                      background: getTrackBackground({
                        values: [currentFrame],
                        colors: ["#ccc", "#548BF4", "#ccc"],
                        min: 0,
                        max: urls.length - 1,
                      }),
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div {...props} className={styles.thumb} />
                )}
                renderTooltip={({ value }) => (
                  <div className={styles.tooltip}>
                    {formatTooltip(value)}
                  </div>
                )}
              />
              <div className={styles.positionLabels}>
                {urls.map((_, index) => (
                  <div
                    key={index}
                    className={`${styles.positionLabel} ${
                      currentFrame === index ? styles.activePosition : ""
                    }`}
                  >
                    {index === 0 ? "00" : index * 12}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.subtitle}>Predicción de frentes</div>
        </div>
      )}
    </div>
  );
};

export default Frentes;
